import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll"; // Import Link from react-scroll

// Styling Files
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

// Relative Imports - Components
import { AuroraBackground } from "../Components/AuroraBackground";

const HeroSection = () => {
  const textVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  const titleVariant = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: "easeOut",
        delay: 0.5,
      },
    },
  };

  return (
    <AuroraBackground>
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="relative flex flex-col gap-4 items-center justify-center px-4"
      >
        <div className="text-3xl md:text-7xl font-bold dark:text-white text-center">
          Mosquito surveillance made smart
        </div>
        <div className="font-extralight text-base md:text-4xl dark:text-neutral-200 py-4">
          Presenting, Digitomy
        </div>
        <Link to="about" smooth={true} duration={500} offset={-100}> 
          <button className="p-[3px] relative">
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg" />
            <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent">
              Learn more
            </div>
          </button>
        </Link>
      </motion.div>
    </AuroraBackground>
  );
};

export default HeroSection;
