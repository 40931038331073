import React from "react";

// Styling Files
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

// Relative Imports - Components
import { motion } from "framer-motion";
import { useState } from "react";
import { useEffect, useRef } from "react";

// Relative Imports - Assets and Animations
import sriram from "../../Assets/TeamMemberAssets/sriram.jpg";
import ryan from "../../Assets/TeamMemberAssets/ryan.png";
import ramesh from "../../Assets/TeamMemberAssets/ramesh.png";
import ramya from "../../Assets/TeamMemberAssets/ramya.png";
import tahmid from "../../Assets/TeamMemberAssets/tahmid.png";
import brandon from "../../Assets/TeamMemberAssets/brandon.png";
import farhat from "../../Assets/TeamMemberAssets/farhat.png";
import fahim from "../../Assets/TeamMemberAssets/fahim.png";
import otabek from "../../Assets/TeamMemberAssets/otabek.png";
import ahsan from "../../Assets/TeamMemberAssets/ahsan.png";

// To add a new member, add the image to the Assets folder and import it here. Then add the member to the members array below.
// The member object should have the following properties:
// 1. name: The name of the member
// 2. position: The position of the member
// 3. positionDescription: A brief description of the member's position
// 4. profilePicture: The imported image of the member
// 5. portfolio: The LinkedIn profile of the member

// Make a cool profile picture by using canva. Remove the background using edit image option, change the background to a gradient color and save it as a png file.

const members = [
  {
    name: "Ramya Thiagarjan",
    position: "CEO",
    positionDescription: "",
    profilePicture: ramya,
    portfolio: "https://www.linkedin.com/in/ramya-thiagarajan-42600b9/",
  },
  {
    name: "Ramesh Saligamé",
    position: "Business Manager",
    positionDescription:
      "Assistant Professor of Digital Science at the University of South Florida.",
    profilePicture: ramesh,
    portfolio: "https://www.linkedin.com/in/rameshsaligame/",
  },
  {
    name: "Sriram Chellappan",
    position: "University of South Florida",
    positionDescription:
      "Professor in the Department of Computer Science and Engineering at University of South Florida.",
    profilePicture: sriram,
    portfolio: "https://www.linkedin.com/in/sriram-chellappan-20bb8051/",
  },
  {
    name: "Ryan Carney",
    position: "University of South Florida",
    positionDescription:
      "Assistant Professor of Digital Science at the University of South Florida.",
    profilePicture: ryan,
    portfolio: "https://www.linkedin.com/in/ryancarney/",
  },
  // {
  //   name: "Brandon Wolfram",
  //   position: "Hardware Design Engineer",
  //   positionDescription:
  //     "Hardware Design Engineer and a USF Alum. He is currently pursuing a graduate degree from Georgia-Tech.",
  //   profilePicture: brandon,
  //   portfolio: "https://www.linkedin.com/in/brandonmwolfram/",
  // },

  {
    name: "Fahim Rahman Siam",
    position: "Graduate student at USF",
    positionDescription:
      "Studying in the Department of Computer Science and Engineering at University of South Florida.",
    profilePicture: fahim,
    portfolio: "https://www.linkedin.com/in/fahim-rahman-siam-2160411b2/",
  },
  {
    name: "Farhat Binte Azam",
    position: "Graduate student at USF",
    positionDescription:
      "Studying in the Department of Computer Science and Engineering at University of South Florida.",
    profilePicture: farhat,
    portfolio: "https://www.linkedin.com/in/farhat-binte-azam-4415a9144/",
  },
  {
    name: "Ahsan Wahab",
    position: "Graduate student at USF",
    positionDescription:
      "Studying in the Department of Computer Science and Engineering at University of South Florida.",
    profilePicture: ahsan,
    portfolio: "https://www.linkedin.com/in/ahsan-wahab/",
  },
  {
    name: "Tahmid Ahmed",
    position: "Undergraduate student at USF",
    positionDescription:
      "Studying in the Department of Computer Science and Engineering at University of South Florida.",
    profilePicture: tahmid,
    portfolio: "https://www.linkedin.com/in/tahmid-ahmed-72a883212/",
  },
  {
    name: "Otabek Abduraufov",
    position: "Undergraduate student at USF",
    positionDescription:
      "Studying in the Department of Computer Science and Engineering at University of South Florida.",
    profilePicture: otabek,
    portfolio: "https://www.linkedin.com/in/abduraufov/",
  },
];

const TeamSection = () => {
  const [visibleIndexes, setVisibleIndexes] = useState([]);
  const ref = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = entry.target.dataset.index;
          if (entry.isIntersecting) {
            setVisibleIndexes((prev) => [...new Set([...prev, index])]);
          } else {
            setVisibleIndexes((prev) => prev.filter((i) => i !== index));
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    ref.current.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div
      style={{
        background: "linear-gradient(90deg, #A2B6D1, #fff, #fff, #EAE5F9)",
        padding: "40px 20px",
      }}
    >
      <h2
        style={{
          color: "black",
          fontSize: "36px",
          textAlign: "center",
          fontFamily: "'Plus Jakarta Sans', sans-serif",
        }}
      >
        Our Team
      </h2>
      {/* <p
          style={{
            color: "black",
            fontSize: "16px",
            textAlign: "center",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            marginBottom: "40px",
          }}
        >
          Dedicated professionals working together to make a difference
        </p> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {members.slice(0, 4).map((member, index) => (
          <motion.div
            key={index}
            className="team-card"
            style={{
              backgroundColor: "#353333",
              borderRadius: "20px",
              overflow: "hidden",
              textAlign: "center",
              width: "200px",
              height: "300px",
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              margin: "10px",
              cursor: "pointer",
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ y: 20, opacity: 0 }}
            animate={
              visibleIndexes.includes(String(index)) ? { y: 0, opacity: 1 } : {}
            }
            transition={{ type: "spring", stiffness: 100, delay: index * 0.1 }}
            onClick={() => window.open(member.portfolio, "_blank")}
            data-index={index}
            ref={(el) => (ref.current[index] = el)}
          >
            <img
              src={member.profilePicture}
              alt={member.name}
              style={{
                width: "100%",
                height: "70%",
                objectFit: "cover",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            />
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                padding: "10px",
                borderRadius: "0 0 20px 20px",
                width: "100%",
                position: "absolute",
                bottom: "0",
                left: "0",
                boxShadow: "0 -4px 8px rgba(0,0,0,0.2)",
              }}
            >
              <h3
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  color: "#fff",
                }}
              >
                {member.name}
              </h3>
              <p
                style={{
                  margin: "0",
                  fontSize: "12px",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  color: "#ddd",
                }}
              >
                {member.position}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {members.slice(4).map((member, index) => (
          <motion.div
            key={index}
            className="team-card"
            style={{
              backgroundColor: "#353333",
              borderRadius: "20px",
              overflow: "hidden",
              textAlign: "center",
              width: "200px",
              height: "300px",
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              margin: "10px",
              cursor: "pointer",
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ y: 20, opacity: 0 }}
            animate={
              visibleIndexes.includes(String(index + 4))
                ? { y: 0, opacity: 1 }
                : {}
            }
            transition={{
              type: "spring",
              stiffness: 100,
              delay: (index + 4) * 0.1,
            }}
            onClick={() => window.open(member.portfolio, "_blank")}
            data-index={index + 4}
            ref={(el) => (ref.current[index + 4] = el)}
          >
            <img
              src={member.profilePicture}
              alt={member.name}
              style={{
                width: "100%",
                height: "70%",
                objectFit: "cover",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            />
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                padding: "10px",
                borderRadius: "0 0 20px 20px",
                width: "100%",
                position: "absolute",
                bottom: "0",
                left: "0",
                boxShadow: "0 -4px 8px rgba(0,0,0,0.2)",
              }}
            >
              <h3
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  color: "#fff",
                }}
              >
                {member.name}
              </h3>
              <p
                style={{
                  margin: "0",
                  fontSize: "12px",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  color: "#ddd",
                }}
              >
                {member.position}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
