import React from "react";

// Styling Files
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

const ContactSection = () => {
    return (
      <div
        style={{
          background: "linear-gradient(90deg, #100F0F,#322F2F,#322F2F,#100F0F)",
          color: "#fff",
          padding: "10px 20px",
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          textAlign: "center",
        }}
        id="contact"
      >
        <h2 style={{ fontSize: 36, marginBottom: "20px" }}>Contact Us</h2>
        <p
          style={{
            fontSize: 20,
            margin: "0 auto 40px",
            maxWidth: "800px",
          }}
        >
          For any inquiries, please email us at{" "}
          <a
            href="mailto:digitomy@gmail.com"
            style={{ color: "grey", textDecoration: "none" }}
          >
            digitomy.tech@gmail.com
          </a>
        </p>
      </div>
    );
  };

  
  export default ContactSection;