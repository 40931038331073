import React from "react";

// Styling Files
import "react-awesome-button/dist/styles.css";

import HeroSection from "./Sections/HeroSection";
import AboutSection from "./Sections/AboutSection";
import RecognitionsSection from "./Sections/RecognitionsSection";
import DemoSection from "./Sections/DemoSection";
import TeamSection from "./Sections/TeamSection";
import CollaboratorsSection from "./Sections/CollaboratorsSection";
import PatentsSection from "./Sections/PatentsSection";
import ContactSection from "./Sections/ContactsSection";
import Navbar from "./Sections/Navbar";

const LandingPage = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar />
      <section id="home">
        <HeroSection />
      </section>
      <section id="about">
        <AboutSection />
      </section>
      <section id="recognitions">
        <RecognitionsSection />
      </section>
      <section id="demo">
        <DemoSection />
      </section>
      <section id="team">
        <TeamSection />
      </section>
      <section id="collaborators">
        <CollaboratorsSection />
      </section>
      <section id="patents">
        <PatentsSection />
      </section>
      <section id="contact">
        <ContactSection />
      </section>
    </div>
  );
};

export default LandingPage;
