import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ReactPlayer from "react-player";
import { ImagesSlider } from "../Components/ImagesSlider";
import hardwareVideo from "../../Assets/TrapAssets/hardware.mp4";
import innerWorkingVideo from "../../Assets/TrapAssets/hardware.mp4";
import slide1 from "../../Assets/TrapAssets/trapImage1.png";
import slide2 from "../../Assets/TrapAssets/trapImage2.jpeg";
import slide3 from "../../Assets/TrapAssets/trapImage3.jpeg";
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

const DemoSection = () => {
  const [selectedTab, setSelectedTab] = useState("hardware");
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const images = [slide1, slide2, slide3];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        } else {
          setInView(false);
        }
      },
      { threshold: 0.5 } // Adjust this threshold as needed
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const tabVariants = {
    initial: {
      opacity: 0,
      x: 100,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    exit: {
      opacity: 0,
      x: -100,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  // DemoSection.jsx
const buttonStyle = {
  padding: "20px",
  borderRadius: "10px",
  cursor: "pointer",
  textAlign: "left",
  // flex: "1 1 calc(20% - 20px)",
  minWidth: "100px",
  marginTop: "10px",
  maxWidth: "120px",
};


  return (
    <div
      ref={sectionRef}
      style={{
        padding: "40px 20px",
        width: "100%",
        background: "linear-gradient(90deg, #100F0F,#322F2F,#322F2F,#100F0F)",
      }}
    >
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap", // Add this line

            marginBottom: "20px",
          }}
        >
          <div style={{ width: "100%" }}>
            <h2
              style={{
                fontSize: "32px",
                fontWeight: "700",
                margin: "0",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                color: "#fff",
              }}
            >
              Demo
            </h2>
            <p
              style={{
                fontSize: "16px",
                color: "#ccc",
                marginTop: "10px",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            >
             
            </p>
          </div>
          
        </div>
        <div
          style={{
            backgroundColor: "#404243",
            borderRadius: "10px",
            padding: "20px",
            height: "30rem",
            width: "100%",
          }}
        >
          <AnimatePresence>
            {selectedTab === "hardware" && (
              <div key="hardware" style={{ height: "100%" }}>
                <ReactPlayer
                  url={hardwareVideo}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            )}
            {selectedTab === "innerWorking" && (
              <div key="innerWorking" style={{ height: "100%" }}>
                <ReactPlayer
                  url={innerWorkingVideo}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            )}
            {selectedTab === "slideshow" && (
              <div key="slideshow" style={{ height: "100%" }}>
                <ImagesSlider className="h-full" images={images}>
                  <motion.div
                    initial={{ opacity: 1, y: -80 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="z-50 flex flex-col justify-center items-center"
                  ></motion.div>
                </ImagesSlider>
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default DemoSection;
