import React from "react";

// Styling Files
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

import nasa from "../../Assets/CollaboratorsAssets/nasa.png";
import nsf from "../../Assets/CollaboratorsAssets/nsf.png";
import usf from "../../Assets/CollaboratorsAssets/usf.png";
import cdc from "../../Assets/CollaboratorsAssets/cdc.png";


const NSFcont =
  "NSF's support has helped to fund research and development of the mosquito trap, bringing us one step closer to our goal of reducing mosquito-borne illnesses around the world";
const USFcont =
  "USF's research facilities and expertise in environmental science, entomology, and computer science have contributed to the development and refinement of the mosquito trap.";
const CDCcont =
  "The CDC's guidance and access to disease data have informed the design and deployment of the mosquito trap to help reduce the spread of mosquito-borne illnesses.";
const NASAcont =
  "NASA's technical expertise and funding have supported the development of advanced sensors and algorithms for the mosquito trap";

const defaultcont =
  "We are incredibly grateful for the contributions of our sponsors and partners. Thanks to their generous support, we have been able to develop and refine our mosquito trap,\n bringing us one step closer to our goal of reducing mosquito-borne illnesses around the world";

const CollaboratorsSection = () => {
  const [contribution, setContribution] = React.useState(defaultcont);
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #100F0F,#322F2F,#322F2F,#100F0F)",
        color: "#FFFFFF",
        padding: "40px 0",
      }}
      id="sponsors"
    >
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h2
          style={{
            fontSize: 36,
            // fontWeight: "700",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
        >
         Collaborators 
        </h2>
      </div>
      <div
  style={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
    marginBottom: "40px",
  }}
>
        <img
          src={nasa}
          alt="NASA logo"
          className="nasa"
          style={{
            width: "100%",
            maxWidth: "160px",
            height: "auto",
            cursor: "pointer",
            transition: "transform 0.3s",
          }}
          onMouseEnter={(e) => {
            setContribution(NASAcont);
            e.currentTarget.style.transform = "scale(1.2)";
          }}
          onMouseLeave={(e) => {
            setContribution(defaultcont);
            e.currentTarget.style.transform = "scale(1)";
          }}
          onClick={() => window.open("https://www.nasa.gov/", "_blank")}
        />
        <img
          src={nsf}
          alt="NSF logo"
          className="nsf"
          style={{
            width: "160px",
            cursor: "pointer",
            transition: "transform 0.3s",
          }}
          onMouseEnter={(e) => {
            setContribution(NSFcont);
            e.currentTarget.style.transform = "scale(1.2)";
          }}
          onMouseLeave={(e) => {
            setContribution(defaultcont);
            e.currentTarget.style.transform = "scale(1)";
          }}
          onClick={() => window.open("https://www.nsf.gov/", "_blank")}
        />
        <img
          src={usf}
          alt="USF logo"
          className="usf"
          style={{
            width: "160px",
            cursor: "pointer",
            transition: "transform 0.3s",
          }}
          onMouseEnter={(e) => {
            setContribution(USFcont);
            e.currentTarget.style.transform = "scale(1.2)";
          }}
          onMouseLeave={(e) => {
            setContribution(defaultcont);
            e.currentTarget.style.transform = "scale(1)";
          }}
          onClick={() => window.open("https://www.usf.edu/", "_blank")}
        />
        <img
          src={cdc}
          alt="CDC logo"
          className="cdc"
          style={{
            width: "160px",
            cursor: "pointer",
            transition: "transform 0.3s",
          }}
          onMouseEnter={(e) => {
            setContribution(CDCcont);
            e.currentTarget.style.transform = "scale(1.2)";
          }}
          onMouseLeave={(e) => {
            setContribution(defaultcont);
            e.currentTarget.style.transform = "scale(1)";
          }}
          onClick={() => window.open("https://www.cdc.gov/", "_blank")}
        />
      </div>
     
    </div>
  );
};

export default CollaboratorsSection;