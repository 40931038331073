import React from "react";


// Styling Files
import "../../styles.css";
import "react-awesome-button/dist/styles.css";

// Relative Imports - Components
import patentImage1 from "../../Assets/PatentAssets/patentImage1.png";
import patentImage2 from "../../Assets/PatentAssets/patentImage2.png";
import patentImage3 from "../../Assets/PatentAssets/patentImage3.png";


const patentsData = [
  {
    title: "US Patent 10,963,742",
    description:
      "Sriram Chellappan, Pratool Bharti, Mona Minakshi, Willie McClinton and Jamshidbek Mirzakhalov, “Leveraging smart-phone cameras and image processing techniques to classify mosquito genus and species”",
    link: "https://patents.google.com/patent/US20200143202A1/en?q=(sriram+chellepan)&oq=sriram+chellepan",
    image: patentImage1,
  },
  {
    title: "US Patent 11,048,928",
    description:
      "Sriram Chellappan, Mona Minakshi, Jamshidbek Mirzakhalov, Sherzod Kariev and Willie McClinton, “Systems and methods of entomology classification based on extracted anatomies”",
    link: "https://patents.google.com/patent/US11048928B1/en?q=(sriram+chellepan)&oq=sriram+chellepan",
    image: patentImage2,
  },
  {
    title: "Application US17/496,563 (Pending)",
    description:
      "Sriram Chellappan, Stephen Edward Saddow, Ryan Marc Carney, Brandon Wolfram and Mark Weston, “Smart mosquito trap for mosquito classification”",
    link: "https://patents.google.com/patent/WO2022076702A1/en?q=(sriram+chellepan)&oq=sriram+chellepan",
    image: patentImage3,
  },
];

const PatentsSection = () => {
  return (
    <div className="patents-section" id="patents">
      <h2>Patents/Patent Applications</h2>
     
      <div className="patent-container">
        {patentsData.map((patent, index) => (
          <Card key={index} {...patent} />
        ))}
      </div>
    </div>
  );
};
const Card = ({ title, description, link, image }) => {
  return (
    <div
      className="patent-card"
      onClick={() => window.open(link, "_blank")}
    >
      <img src={image} alt={title} />
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  );
};
  
  export default PatentsSection;